import React, { useState } from 'react';
import '../../Styles/DownloadBeta.scss';
import { BarLoader } from 'react-spinners';
import { Link, useHistory } from 'react-router-dom';
import { downloadService } from '../../services/download.service';
import { useUser } from '../../contexts/userContext';
import { Helmet } from 'react-helmet';
import AlternativePaymentLayout from '../../components/Payment/AlternativePaymentLayout';
import Layout from '../../components/Layout';
import androidLogo from '../../assets/icons/android_logo.svg';
import windowsLogo from '../../assets/icons/windows_logo.svg';
import andLogo from '../../assets/icons/and_logo.svg';
// import macTextLogo from '../../assets/icons/mac_text_logo.svg';
import macTextLogo from '../../assets/icons/mac_text_logo2.svg';
import windowsTextLogo from '../../assets/icons/windows_text_logo.svg';
import arrowRight from '../../assets/icons/arrow_right.svg';
import mobile from '../../assets/icons/mobile.svg';
import plus from '../../assets/icons/plus.svg';
import laptop from '../../assets/icons/laptop.svg';
import equal from '../../assets/icons/equal.svg';
import truhu_logo from '../../assets/icons/truhu_logo.svg';
import iphoneTextLogo from '../../assets/icons/iphone_text_logo.svg';
import iphoneTextLogo2 from '../../assets/icons/iPhoneTextLogo2.svg';
import andriodTextLogo from '../../assets/icons/andriodTextLogo.svg';
import altAndroidImage from '../../assets/images/android.png';
// import altAndroidImage from '../../assets/images/and.png';
import downloadIcon from '../../assets/icons/download_icon.svg';
import smallLogo from '../../assets/icons/logo_circle.svg';
import appStoreDownloadLogo from '../../assets/icons/App_Store.svg';
import altMacImage from '../../assets/images/altMacImage.png';
import altWinImage from '../../assets/images/altWinImage.png';
import iphoneImage from '../../assets/images/iphoneImage.png';
import headImage from '../../assets/images/head_Image.png';
import backgroundGradient from '../../assets/images/truhu_bg_gradient.png';

const DownloadBeta = () => {
  const {
    state: { hasBetaAccess }
  } = useUser();
  const history = useHistory();
  const {
    state: { isLoggedIn }
  } = useUser();
  const [loading, setLoading] = useState(false);
  const [loadingWin, setLoadingWin] = useState(false);
  const betaVersions = [
    // Mac Builds
       { no: 330, date: 'November 5, 2024' }  // Hard coded device, Working on new Monitor list
//    { no: 328, date: 'October 26, 2024' }  // Espresso 15" 2 Models, Calibration Reminder in Subscribe Screen
//    { no: 324, date: 'October 15, 2024' }  // Eizo & Espresso Support, Log Fixes
//    { no: 323, date: 'October 2, 2024' }  // Eizo & Espresso Support
    //  { no: 319, date: 'September 25, 2024' } // v2.8 iPhone 16 Support
   // { no: 318, date: 'September 12, 2024' }
   // { no: 317, date: 'September 7, 2024' }
   // { no: 316, date: 'August 29, 2024' }
    // { no: 315, date: 'April 30, 2024' }
    //    { no: 313, date: 'April 16, 2024' }
    //      { no: 311, date: 'April 8, 2024' }
    //     { no: 310, date: 'March 26, 2024' }
    //     { no: 303, date: 'February 7, 2024' }
    //    { no: 296, date: 'January 23, 2024' }
    //     { no: 289, date: 'December 5, 2023' }
    //    { no: 288, date: 'November 23, 2023' }
    //    { no: 284, date: 'October 2, 2023' }
    //    { no: 281, date: 'September 22, 2023' }
    //{ no: 275, date: 'September 9, 2023' }
    //{ no: 272, date: 'August 18, 2023' }
    //{ no: 266, date: 'June 23, 2023' }
    //{ no: 258, date: 'May 12, 2023' }
    // { no: 242, date: 'May 5, 2023' }
    // { no: 235, date: 'Apr 25, 2023' }
    // {no: 234, date: 'Apr 19, 2023'},
    // {no: 232, date: 'Apr 11, 2023'},
    // {no: 226, date: 'Mar 28, 2023'},
    // {no: 220, date: 'Mar 1, 2023'},
  ];
  // const alphaVersions = [
  //   {no: 206, date: 'Jan 10, 2023'},
  //   {no: 205, date: 'Jan 6, 2023'},
  //   {no: 204, date: 'Jan 5, 2023'},
  //   {no: 203, date: 'Jan 4, 2023'},
  //   {no: 202, date: 'Jan 3, 2023'},
  //   {no: 201, date: 'Dec 29, 2022'},
  // ]
  // const preAlphaVersions = [
  //   {no: 200, date: 'Dec 27, 2022'},
  //   {no: 198, date: 'Dec 23, 2022'},
  //   {no: 197, date: 'Dec 21, 2022'},
  // ]
  const WinBetaVersions = [
    // Windows Releases
    { no: "2.26", date: 'December 7, 2024' },   // Fix for 10:20 and 10:5 Error, Added logging try to fix Win crash on launch
//    { no: "2.25", date: 'November 15, 2024' },   // Fix for 10:50 Error, Log Fixes
//      { no: "2.19", date: 'October 29, 2024' },   // Fix for 10:50 Error, Log Fixes
//     { no: "2.17", date: 'October 18, 2024' },   // Crash on launch, Error #10:4
//      { no: "2.15", date: 'October 15, 2024' }, // Eizo & Espresso Support, Log Fixes, Parsing Build # Fix
//    { no: "2.12", date: 'October 3, 2024' },   
//     { no: "2.00", date: 'September 25, 2024' },  // Support for iPhone16
//       { no: "1.97", date: 'September 10, 2024' },
//     { no: "1.96", date: 'September 8, 2024' }
//    { no: "1.95", date: 'September 5, 2024' }
//     { no: "1.94", date: 'September 3, 2024' }
//        { no: "1.93", date: 'August 26, 2024' }
//      { no: "1.92", date: 'August 18, 2024' }
//     { no: "1.91", date: 'August 17, 2024' }
//      { no: "1.90", date: 'August 13, 2024' }
//     { no: "1.88", date: 'July 30, 2024' }
//    { no: "1.81", date: 'June 06, 2024' }
  ];

  const latest = betaVersions.shift();
  const latestWinBuild = WinBetaVersions.shift();

  const downloadDMG = async (title: string, buildType: string) => {
    buildType === 'mac' ? setLoading(true) : setLoadingWin(true)
    try {
      await downloadService.downloadBeta(title, buildType);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      setLoadingWin(false)
    }
  };

  // if (hasBetaAccess === null) {
  //   return null
  // }
  //
  // if (hasBetaAccess === false) {
  //   history.push('/');
  //   return null
  // }

  const redirectAppStore = `https://apps.apple.com/us/app/TRUHu/id${process.env.REACT_APP_APPLE_APP_ID}/`;

  return (
    <Layout>
      <Helmet>
        <title>Download the TruHu App - Monitor Color Calibration Tool | Good Color Made Easy</title>
        <meta
          name="description"
          content="Download the TruHu Monitor Color Calibration app on your preferred device and enter a world of optimized displays, color-perfect work, and happy clients!"
        />
      </Helmet>
      <div className='alt__payment__page'>
        <div className="alt__payment__top__title__container">
          <h1 className="alt__payment__page__title">Download TruHu</h1>
        </div>
        <div className="alt__payment__top__description" style={{ position: 'relative' }}>

          <div style={{ margin: '0px 15%' }}>
          <div className="head__image">
            <img src={mobile} className='img1' alt="headImage logo" />
            <img src={plus} className='img2' alt="headImage logo" />
            <img src={laptop} className='img3' alt="headImage logo" />
            <img src={equal} className='img4' alt="headImage logo" />
            <img src={truhu_logo} className='img5' alt="headImage logo" />
          </div>
            <div className="head__image2">
              <img src={mobile} className='img1b' alt="headImage logo" />
              <img src={plus} className='img2b' alt="headImage logo" />
              <img src={laptop} className='img3b' alt="headImage logo" />
              <img src={equal} className='img4b' alt="headImage logo" />
              <img src={truhu_logo} className='img5b' alt="headImage logo" />
            </div>
           <div className='desc__div'>
           <p className="desc__div__p">TruHu uses a Desktop and Mobile app together to measure your display’s colors <br /> and proceed with calibration  <Link to="/product" className={'colored-link'}>
              Learn more
            </Link>. </p>
            <p className="desc__div__p">To continue, please download, open, and sign in to both apps:</p>
           </div>
          </div>
        </div>
        <div className='both__os'>
          <AlternativePaymentLayout>
            <div className='mac__div'>
              <h1 className="alt__payment__page__subtitle1 ">Desktop</h1>
              <div className='two__boxes'>
                <div className="alt__payment__form">
                  <img className="alt__bg__gradient rotating" src={backgroundGradient} alt="bg_gradient" />
                  <div className="alt__payment__list">
                    <img src={macTextLogo} alt="mac text logo" />
                    <div className="alt__payment__image__container">
                      <img src={altMacImage} alt="mac image" />
                    </div>
                    <div className="loader-wrap">
                      {loading && (
                        <>
                          Downloading... <BarLoader width={200} />
                        </>
                      )}
                    </div>

                    <div className="alt__payment__list__bottom">
                      <button className="download__mac__button"
                        onClick={() => downloadDMG(`TruHu+Desktop+${latest?.no}.dmg`, "mac")}>
                        <img src={smallLogo} alt="logo" />
                        Download Desktop for Mac
                        <img src={downloadIcon} alt="download" />
                      </button>
                      {betaVersions.map(({ no, date }) => (
                        <div key={no} className="flex-sb">
                          <a className="colored-link" onClick={() => downloadDMG(`TruHu+Desktop+${no}.dmg`, "mac")}>
                            Download {no}
                          </a>
                          <div className="date">{date}</div>
                        </div>
                      ))}

                    </div>
                  </div>

                </div>
                <div className="alt__payment__form alt__payment__form__right">
                  <img className="alt__bg__gradient rotating" src={backgroundGradient} alt="bg_gradient" />
                  <div className="alt__payment__list">
                    <img src={windowsTextLogo} alt="win text logo" />
                    <div className="alt__payment__image__container">
                      <img src={altWinImage} alt="win image" />
                    </div>
                    <div className="loader-wrap">
                      {loadingWin && (
                        <>
                          Downloading... <BarLoader width={200} />
                        </>
                      )}
                    </div>

                    <div className="alt__payment__list__bottom">
                      <button className="download__mac__button"
                        onClick={() => {
                          downloadDMG(`TruHu+Installer+${latestWinBuild?.no}.msi`, "windows")
                        }}
                      >
                        <img src={smallLogo} alt="logo" />
                        Download Desktop for Windows
                        <img src={downloadIcon} alt="download" />
                      </button>
                      {WinBetaVersions.map(({ no, date }) => (
                        <div key={no} className="flex-sb">
                          <a className="colored-link" onClick={() => downloadDMG(`TruHu+Installer+${no}.msi`, "windows")}>
                            Download {no}
                          </a>
                          <div className="date">{date}</div>
                        </div>
                      ))}

                    </div>
                  </div>

                </div>
              </div>
            </div>



            <div className='win__div'>
              <h1 className="alt__payment__page__subtitle2">Mobile</h1>

              <div className='two__boxes  pb-5'>
                <div className="alt__payment__form ">
                  <img className="alt__bg__gradient rotating" src={backgroundGradient} alt="gradient background" />
                  <div className="alt__payment__list">
                    <img src={iphoneTextLogo2} alt="iphone text logo" />
                    <div className="alt__payment__image__container alt__payment__image__container__horizontal">
                      <img src={iphoneImage} alt="iphone image" />
                    </div>
                    {/* <div className="loader-wrap"></div> */}
                    <div className="alt__payment__list__bottom mt-3">
                      <a href={redirectAppStore} className="alt__app__store__link">
                        <img src={appStoreDownloadLogo} alt="download on app store" />
                      </a>
                    </div>
                  </div>

                </div>
                <div className="alt__payment__form alt__payment__form__right ">
                  <img className="alt__bg__gradient rotating" src={backgroundGradient} alt="bg_gradient" />
                  <div className="alt__payment__list">
                    <img src={andriodTextLogo} alt="andriod text logo" />
                    <div className="alt__payment__image__container alt__payment__image__container__horizontal">
                      <img src={altAndroidImage} alt="android image" />
                    </div>
                    {/* <div className="loader-wrap"></div> */}

                    <div className="alt__payment__list__bottom mt-3">

                      <Link to={'/wait-list'}>
                        <div className="later__container">
                          <div className="later__top__row">
                            <img src={andLogo} alt="android logo" />
                            <span>Coming</span>
                          </div>
                          <div className="later__top__row">
                            <span> &nbsp;&nbsp;Click to be notified</span>
                            <img src={arrowRight} alt="arrow right" />
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </AlternativePaymentLayout>


          {/* <AlternativePaymentLayout>
      <div className="alt__payment__form alt__payment__form__right">
          <img className="alt__bg__gradient rotating" src={backgroundGradient} alt="gradient background" />
          <div className="alt__payment__list">
            <img src={iphoneTextLogo2} alt="iphone text logo" />
            <div className="alt__payment__image__container">
              <img src={iphoneImage} alt="iphone image" />
            </div>
            <div className="loader-wrap"></div>
            <div className="alt__payment__list__bottom">
              <a href={redirectAppStore} className="alt__app__store__link">
                <img src={appStoreDownloadLogo} alt="download on app store" />
              </a>
              
            </div>
          </div>

        </div>
        <div className="alt__payment__form">
          <img className="alt__bg__gradient rotating" src={backgroundGradient} alt="bg_gradient" />
          <div className="alt__payment__list">
            <img src={andriodTextLogo} alt="andriod text logo" />
            <div className="alt__payment__image__container">
              <img src={altAndroidImage} alt="andriod image"/>
            </div>
            <div className="loader-wrap">
              {loading && (
                <>
                  Downloading... <BarLoader width={200} />
                </>
              )}
            </div>

            <div className="alt__payment__list__bottom">
    
              <Link to={'/wait-list'}>
                <div className="later__container">
                  <div className="later__top__row">
                    <img src={andLogo} alt="android logo" />
                    <span>Coming</span>
                    <span> - &nbsp;&nbsp;Click to be notified</span>
                    <img src={arrowRight} alt="arrow right" />
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </div>
        
      </AlternativePaymentLayout> */}
        </div>
      </div>

    </Layout>
  );
};

export default DownloadBeta;
